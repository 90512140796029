import * as React from 'react';

import Layout from 'shared/CourseIeltsDetail/Layout';
import { GCMSDifferencesHome } from 'shared/CourseLanding/DifferencesHome';
import StudentReviews from 'shared/StudentCourseLanding/StudentReviews';
import { Course } from 'shared/CourseIeltsDetail/section/Course';

const ieltsDesc =
  'Không chỉ dừng lại ở IELTS hay TOEIC, DOL còn xây dựng các khóa học SAT, GMAT, GRE ứng dụng Linearthinking giúp việc học trở nên Tư duy hơn, Hiệu quả hơn. Ngoài ra, DOL còn có các khóa Tiếng Anh tổng quát giúp cải thiện phát âm và giao tiếp tiếng Anh tốt hơn.';
const CoursePage = ({ location }) => {
  return (
    <Layout
      slug={location.pathname}
      title="Khóa học"
      highlight="khác"
      ieltsDesc={ieltsDesc}
      courseTop
      seoTitle="Các khóa học tiếng Anh từ cơ bản đến nâng cao"
      featureImg="dwUUP8TiyYIewvOSAAKw"
      seoDesc="Các khóa học tiéng Anh phù hợp với mọi trình độ do DOL English xây dựng ứng dụng Linearthinking giúp việc học trở nên Tư duy hơn, Hiệu quả hơn."
    >
      <Course
        title="Các khóa học khác tại DOL"
        level="Khóa học khác"
        isOtherCourse
      />
      <GCMSDifferencesHome showIcon={false} />
      <StudentReviews
        program="IELTS"
        title="Review học viên khóa IELTS tại DOL"
        columns={{ default: 3, 1143: 2, 775: 1 }}
      />
      {/* <HallOfFame showTitle program="OTHER" /> */}
    </Layout>
  );
};

export default React.memo(CoursePage);
